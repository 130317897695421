import React, { FC } from 'react'
import classNamesBind from 'classnames/bind'

import NewFooter from '@dashboard/components_v2/global/Footer'
import Footer from './components/Footer'
import Navigation from '@dashboard/components_v2/global/Navigation'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  white?: boolean
  asPath?: string
  hideNav?: boolean
  minimalFooter?: boolean
  basicsOnly?: boolean
  customLogo?: string | null
  children?: React.ReactNode
}

const Public: FC<OwnProps> = ({
  white,
  hideNav,
  minimalFooter,
  basicsOnly,
  children,
}: OwnProps) => {
  // Partials
  const renderNavigation = () => {
    if (hideNav) return null

    return (
      <div>
        <Navigation public />
      </div>
    )
  }

  const renderFooter = () => {
    if (basicsOnly || minimalFooter)
      return <Footer minimalFooter={minimalFooter} basicsOnly={basicsOnly} />
    return <NewFooter />
  }

  return (
    <div className={classNames('wrapper', { white })}>
      {renderNavigation()}
      {children}
      {renderFooter()}
    </div>
  )
}

export default Public
