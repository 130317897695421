import React from 'react'
import classNamesBind from 'classnames/bind'
import { APP_URL, HELP_DESK_URL } from '@dashboard/lib/constants'

import ColorIcon from '@dashboard/components/ColorIcon'
import Link from 'next/link'
import LogoSVG from '@dashboard/components/PersonalityPoster/LogoSVG'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  minimalFooter?: boolean
  basicsOnly?: boolean
}

interface State {
  selectedSection: string | null
}

export default class Footer extends React.Component<OwnProps, State> {
  constructor(props: OwnProps) {
    super(props)

    this.state = {
      selectedSection: null,
    }
  }

  render() {
    const { basicsOnly } = this.props

    return (
      <div id="footer" className={classNames('wrapper')}>
        <div className={classNames('section-content')}>
          <div className={classNames('footer-wrapper', { basicsOnly })}>
            {this.renderNavLinks()}
            {this.renderSocialLinks()}
            {this.renderPoweredByCrystal()}
            <div className={classNames('footer-details')}>
              © {new Date().getFullYear()} Crystal{' '}
              <span className={classNames('footer-bar')}>|</span>{' '}
              <a
                className={classNames('footer-small-link')}
                href="https://crystalknows.com/privacy"
              >
                Privacy Policy
              </a>{' '}
              <span className={classNames('footer-bar')}>|</span>{' '}
              <a
                className={classNames('footer-small-link')}
                href="https://crystalknows.com/tos"
              >
                Terms
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderNavLinks() {
    const { minimalFooter, basicsOnly } = this.props

    if (minimalFooter || basicsOnly) return null

    return (
      <div className={classNames('footer-grid')}>
        {this.renderColumn('Product', [
          {
            title: 'Overview',
            href: 'https://www.crystalknows.com/products',
          },
          {
            title: 'How it Works',
            href: 'https://www.crystalknows.com/how-predictions-work',
          },
          {
            title: 'Get Started Free',
            href: `${APP_URL}/app/register`,
          },
        ])}
        {this.renderColumn('Personality', [
          {
            title: 'Personality Test',
            href: 'https://www.crystalknows.com/personality-test',
          },
          {
            title: 'DISC Test',
            href: 'https://www.crystalknows.com/disc',
          },
          {
            title:
              '16-Personality Test (based on types by Jung, Myers, & Briggs)',
            href: 'https://www.crystalknows.com/myers-briggs',
          },
          {
            title: 'Enneagram Test',
            href: 'https://www.crystalknows.com/enneagram',
          },
          {
            title: 'Big 5',
            href: '"https://www.crystalknows.com/big-five',
          },
        ])}
        {this.renderColumn('Content', [
          {
            title: 'Book',
            href: 'https://www.crystalknows.com/book',
          },
          {
            title: 'Resources',
            href: 'https://www.crystalknows.com/resources',
          },
          {
            title: 'Blog',
            href: 'https://www.crystalknows.com/video/chrome-extension',
          },
          {
            title: 'Videos',
            href: 'https://www.crystalknows.com/video/chrome-extension',
          },
          {
            title: 'Personality Types',
            href: 'https://www.crystalknows.com/personality-types',
          },
        ])}
        {this.renderColumn('Company', [
          {
            title: 'About Us',
            href: 'hhttps://www.crystalknows.com/about',
          },
          {
            title: 'Jobs',
            href: 'https://crystalknows.workable.com/',
          },
          {
            title: 'Affiliate Program',
            href: 'https://www.crystalknows.com/affiliate-program',
          },
          {
            title: 'FAQ',
            href: HELP_DESK_URL,
          },
          {
            title: 'Contact',
            href: 'https://www.crystalknows.com/contact',
          },
        ])}
      </div>
    )
  }

  renderSocialLinks() {
    const { minimalFooter, basicsOnly } = this.props

    if (minimalFooter || basicsOnly) return null

    return (
      <div className={classNames('footer-social')}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/crystal_knows"
          className={classNames('link')}
        >
          <ColorIcon icon="linkedInIcon" discType="light-blue" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/CrystalKnowsMe"
          className={classNames('link')}
        >
          <ColorIcon icon="twitter" discType="light-blue" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/crystalknowsme/"
          className={classNames('link')}
        >
          <ColorIcon icon="instagram" discType="light-blue" />
        </a>
        <a className={classNames('link')} href="mailto:hello@crystalknows.com">
          <ColorIcon icon="email" discType="light-blue" />
        </a>
      </div>
    )
  }

  renderColumn(header: string, links: { title: string; href: string }[]) {
    const { selectedSection } = this.state

    return (
      <div className={classNames('footer-column')}>
        <div
          className={classNames('column-header')}
          onClick={() => this.toggleSelection(header)}
        >
          {header}
        </div>
        <div
          className={classNames('footer-links', {
            expanded: selectedSection == header,
          })}
        >
          {links.map(link => {
            return (
              <a
                key={`${link.href}-${link.title}`}
                className={classNames('footer-link')}
                href={link.href}
              >
                {link.title}
              </a>
            )
          })}
        </div>
      </div>
    )
  }

  renderPoweredByCrystal() {
    const { minimalFooter, basicsOnly } = this.props

    if (!minimalFooter || basicsOnly) return null

    return (
      <Link
        href={APP_URL}
        className={classNames('crystal-logo-container')}
        passHref
      >
        <div className={classNames('logo-text')}>Powered by</div>
        <div className={classNames('logo-container')}>
          <div className={classNames('logo-line')} />
          <LogoSVG discType={'white'} white />
          <div className={classNames('logo-line')} />
        </div>
        <div className={classNames('logo-text')}>www.crystalknows.com</div>
      </Link>
    )
  }

  toggleSelection = (selection: string) => {
    const { selectedSection } = this.state

    if (selectedSection == selection) this.setState({ selectedSection: null })
    else this.setState({ selectedSection: selection })
  }
}
