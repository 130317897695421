import styles from './Footer.module.scss'
import Link from 'next/link'

export default function Footer() {
  return (
    <footer id="global-footer" className={styles.footer}>
      <div className="sections">
        <div className="section">
          <h4>Product</h4>

          <div className="links">
            <Link href="/products" prefetch={false}>
              Overview
            </Link>
            <Link href="/how-predictions-work" prefetch={false}>
              How it Works
            </Link>
            <Link href="/app/register" prefetch={false}>
              Get Started Free
            </Link>
          </div>
        </div>

        <div className="section">
          <h4>Personality</h4>

          <div className="links">
            <Link href="/personality-test" prefetch={false}>
              Personality Test
            </Link>
            <Link href="/disc" prefetch={false}>
              DISC Test
            </Link>
            <Link href="/myers-briggs" prefetch={false}>
              16-Personality Test (based on types by Jung, Myers, & Briggs)
            </Link>
            <Link href="/enneagram" prefetch={false}>
              Enneagram Test
            </Link>
            <Link href="/big-five" prefetch={false}>
              Big 5
            </Link>
          </div>
        </div>

        <div className="section">
          <h4>Content</h4>

          <div className="links">
            <Link href="/book" prefetch={false}>
              Book
            </Link>
            <Link href="/resources" prefetch={false}>
              Resources
            </Link>
            <Link href="/personality-blog" prefetch={false}>
              16-Personality Test (based on types by Jung, Myers, & Briggs)
            </Link>
            <Link href="/video/chrome-extension" prefetch={false}>
              Videos
            </Link>
            <Link href="/personality-types" prefetch={false}>
              Personality Types
            </Link>
          </div>
        </div>

        <div className="section">
          <h4>Company</h4>

          <div className="links">
            <Link href="/about" prefetch={false}>
              About Us
            </Link>
            <a href="https://crystalknows.workable.com">Jobs</a>
            <Link href="/affiliate-program" prefetch={false}>
              Affiliate Program
            </Link>
            <a href="https://docs.crystalknows.com">FAQ</a>
            <Link href="/contact" prefetch={false}>
              Contact
            </Link>
          </div>
        </div>
      </div>

      <div className="social-links">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/crystal_knows?__hstc=108288733.f8d710240d3c3bc94f7f050ac54d3411.1691078168039.1691178680404.1691183423208.8&__hssc=108288733.15.1691183423208&__hsfp=3529731725"
          aria-label="Crystal on LinkedIn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#a2e2fc"
          >
            <path d="M17.04,17.044H14.078V12.4c0-1.108-.023-2.531-1.543-2.531-1.544,0-1.78,1.2-1.78,2.45v4.723H7.793V7.5h2.845V8.8h.038a3.122,3.122,0,0,1,2.809-1.541c3,0,3.556,1.975,3.556,4.546V17.04ZM4.448,6.194a1.72,1.72,0,1,1,1.72-1.721A1.719,1.719,0,0,1,4.448,6.194Zm1.485,10.85H2.963V7.5h2.97ZM18.52,0H1.475A1.458,1.458,0,0,0,0,1.441V18.559A1.458,1.458,0,0,0,1.475,20H18.519A1.463,1.463,0,0,0,20,18.559V1.441A1.463,1.463,0,0,0,18.519,0Z"></path>
          </svg>
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/CrystalKnowsMe"
          aria-label="Crystal on Twitter"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20.001"
            viewBox="0 0 20 20.001"
            fill="#a2e2fc"
          >
            <g transform="translate(11.999 0.001)">
              <path
                d="M18.749,20H1.249A1.251,1.251,0,0,1,0,18.751V1.25A1.251,1.251,0,0,1,1.249,0h17.5A1.252,1.252,0,0,1,20,1.25v17.5A1.252,1.252,0,0,1,18.749,20Zm-15-6.135h0A7.361,7.361,0,0,0,7.679,15a7.475,7.475,0,0,0,3.128-.653,6.891,6.891,0,0,0,2.285-1.687,7.366,7.366,0,0,0,1.878-4.842c0-.1,0-.206-.008-.325a5.152,5.152,0,0,0,1.279-1.308,5.175,5.175,0,0,1-1.473.4A2.514,2.514,0,0,0,15.9,5.187a5.212,5.212,0,0,1-1.628.611A2.593,2.593,0,0,0,12.4,5,2.547,2.547,0,0,0,9.836,7.524,2.6,2.6,0,0,0,9.9,8.1,7.349,7.349,0,0,1,4.618,5.462a2.5,2.5,0,0,0,.792,3.37,2.6,2.6,0,0,1-1.159-.316.228.228,0,0,0,0,.033,2.538,2.538,0,0,0,2.057,2.474,2.62,2.62,0,0,1-.676.089,2.535,2.535,0,0,1-.482-.045A2.556,2.556,0,0,0,7.543,12.82,5.145,5.145,0,0,1,4.361,13.9,5.469,5.469,0,0,1,3.751,13.866Z"
                transform="translate(-11.999 -0.001)"
              ></path>
            </g>
          </svg>
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/crystalknowsme/"
          aria-label="Crystal on Instagram"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fillRule="evenodd"
            fill="#a2e2fc"
          >
            <path d="M10,0C7.284,0,6.944.012,5.878.06A7.374,7.374,0,0,0,3.45.525,4.884,4.884,0,0,0,1.679,1.679,4.858,4.858,0,0,0,.525,3.45,7.342,7.342,0,0,0,.06,5.878C.01,6.944,0,7.284,0,10s.012,3.056.06,4.123A7.367,7.367,0,0,0,.525,16.55a4.884,4.884,0,0,0,1.154,1.771A4.858,4.858,0,0,0,3.45,19.475a7.341,7.341,0,0,0,2.428.465C6.944,19.99,7.284,20,10,20s3.056-.013,4.123-.06a7.367,7.367,0,0,0,2.428-.465,5.117,5.117,0,0,0,2.925-2.925,7.341,7.341,0,0,0,.465-2.427c.05-1.066.06-1.406.06-4.123s-.013-3.056-.06-4.123a7.367,7.367,0,0,0-.465-2.428,4.884,4.884,0,0,0-1.154-1.771A4.858,4.858,0,0,0,16.55.525,7.342,7.342,0,0,0,14.122.06C13.056.01,12.716,0,10,0Zm0,1.8c2.67,0,2.988.012,4.041.06a5.523,5.523,0,0,1,1.856.345,3.09,3.09,0,0,1,1.15.747,3.09,3.09,0,0,1,.747,1.15,5.523,5.523,0,0,1,.345,1.856C18.188,7.014,18.2,7.33,18.2,10s-.013,2.988-.062,4.041a5.62,5.62,0,0,1-.35,1.856,3.174,3.174,0,0,1-.75,1.15,3.13,3.13,0,0,1-1.15.747,5.556,5.556,0,0,1-1.863.345c-1.062.048-1.375.059-4.05.059s-2.988-.013-4.05-.062a5.653,5.653,0,0,1-1.862-.35,3.112,3.112,0,0,1-1.15-.75,3.059,3.059,0,0,1-.75-1.15,5.653,5.653,0,0,1-.35-1.863c-.038-1.05-.05-1.375-.05-4.037s.012-2.988.05-4.05a5.653,5.653,0,0,1,.35-1.863,2.965,2.965,0,0,1,.75-1.15,2.965,2.965,0,0,1,1.15-.75,5.556,5.556,0,0,1,1.85-.35c1.062-.038,1.375-.05,4.05-.05Zm0,3.065A5.135,5.135,0,1,0,15.135,10,5.135,5.135,0,0,0,10,4.865Zm0,8.469A3.334,3.334,0,1,1,13.334,10,3.334,3.334,0,0,1,10,13.334Zm6.537-8.671a1.2,1.2,0,1,1-1.2-1.2A1.2,1.2,0,0,1,16.537,4.662Z"></path>
          </svg>
        </a>

        <a href="mailto:hello@crystalknows.com" aria-label="Mailto Crystal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 30 30"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="#a2e2fc"
          >
            <g>
              <path
                data-name="Path 2693"
                d="M4.42,4.17H26a2.7,2.7,0,0,1,2.7,2.7v16.2a2.7,2.7,0,0,1-2.7,2.7H4.42a2.7,2.7,0,0,1-2.7-2.7V6.87A2.7,2.7,0,0,1,4.42,4.17Z"
              ></path>
              <path
                data-name="Path 2694"
                d="M28.72,6.87l-13.5,9.45L1.72,6.87"
              ></path>
            </g>
          </svg>
        </a>
      </div>

      <div className="legal">
        <div>© {new Date().getFullYear()} Crystal</div>

        <div>
          <Link href="/privacy">Privacy Policy</Link>
        </div>

        <div>
          <Link href="/tos">Terms</Link>
        </div>
      </div>
    </footer>
  )
}
